// Todo: Remove this localisation file
// @deprecated
export default {
  menu: {
    main: {
      vacancySearch: 'Vacancy search',
      contractDetails: 'Contract details',
      reservationAndUsageHistory: 'Reservation / Usage history',
      membershipInformation: 'Membership information',
      giftTicket: 'Gift ticket',
      exchangePoints: 'Exchange points',
      vacancyNotification: 'Vacancy notification',
      items: {
        viceMemberAndFamilyMember: 'Vice member / Family member',
        remainingPoints: 'Remaining points',
        remainingDeposit: 'Remaining deposit',
        expirationDate: 'Expiration date',
        purchaseAdditionalPoints: 'Purchase additional points',
        reservationChange: 'Reservation change',
        cancelReservation: 'Cancel reservation',
        changePassword: 'Change password',
        changeMembershipInfo: 'Change membership information',
        deputyMemberAndFamilyMember: 'Sub member/ Family member',
        changeApplication: 'Change application',
        orderTickets: 'Order tickets',
        // -------------------- START ------------------------
        viewReceptionStatus: 'View reception status',
        receptionStatusOrHistory: 'View reception status / history',
        pointExchangeUsageHistory: 'Point exchange usage history',
        giftTicketUsageHistory: 'Gift ticket usage history'
        // -------------------- END ------------------------
      }
    },
    sub: {
      newsFromTokyuVacations: 'News from Tokyu Vacations',
      downloadUserGuide: 'Download user guide',
      vacationLifeBackIssues: 'Vacation life back issues'
    }
  },
  title: {
    registration: 'Registration',
    login: 'Login',
    forgotPassword: 'Forgot password',
    resetPassword: 'Reset password',
    registerCreditCard: 'Register credit card',
    registerTicketReservation: 'Register ticket reservation',
    changeCreditCardInfo: 'Change credit card information',
    receiptList: 'Receipt List',
    changePaymentInfo: 'Change payment information',
    changeMembersInfo: 'Change deputy/family member information',
    listOfContracts: 'List of Contracts',
    contractInformationDetails: 'Contract Information Details',
    reservationSearchResults: 'Reservation - Search Results',
    membersOnlySite: 'Vacation member page',
    pageNotFound: 'Page not found',
    membersOnlySiteLogin: 'Member-only site login',
    main: 'Home',
    news: 'News',
    newsDetail: 'News detail',
    contract: 'Contract list',
    contractDetail: 'Contract list details',
    contractReq: 'Contract list request',
    registerWithEmail: 'Register With Email',
    mostRecentBooking: 'Most recent booking',
    booking: 'Reservation / usage history',
    registerNewMember: 'Register New Member',
    vacancySearch: 'Vacancy Search',
    reservationDetails: 'Reservation - Details',
    reservation: 'Reservation',
    selectPlan: 'Booking - Select Plan',
    reservationConfirmation: 'Reservation - Confirmation',
    registerMemberInformation: 'Register your membership information',
    registerPaymentMethod: 'Register a payment method'
  },
  messages: {
    validate: {
      emailIsRequired: 'E-mail is required',
      emailMustBeValid: 'E-mail must be valid',
      passwordIsRequired: 'Password is required',
      passwordRules: 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter'
    }
  },
  authPage: {
    registerEmailAddress: 'Please register your email address',
    reisterEmailForTicket: 'Please register your email address',
    enterAgainToConfirm: 'Please enter again to confirm',
    pleaseCheckEmailAndConfirm: 'Please check email and confirm',
    needsCreditCard: 'To continue to the member\'s website, you must register a new credit card.',
    connfirmEmail: {
      line1: 'We have sent you the URL for registration.',
      line2: 'Please proceed with the registration procedure',
      line3: 'from the URL in the email'
    },
    enterTheInformation: 'Please enter the following information',
    accountCreated: 'Your account has been created, please login using the password you submitted',
    membershipNumber: 'Membership number (incl. hyphens)',
    membersLogInBelow: 'Members can log in below',
    saveYourLoginID: 'Save your login ID',
    forgotYourPassword: 'Forgot your password',
    ticketUsers: 'Ticket users',
    loginFailed: {
      line1: "You can't log in.",
      line2: 'The account you have entered has been deactivated.',
      line3: 'If you would like to use it again, please contact us.'
    },
    loggingInWait: 'Logging in, please wait a moment...',
    enterEmailToResetPassword:
      'Please enter your email address below and we will send you a link to reset your password.',
    linkResetHasBeenSent:
      'A link to reset your password has been sent to your email address. Please check your email and reset your password.',
    enterNewPassword: 'Please enter your new password',
    resetPasswordSuccess:
      'You have successfully reset your password. Please login with your reset password.',
    registerCreditCardNotice:
      'To continue using the members-only site, you need to register new credit card information.',
    securityCodeQuestion: 'What is the security code?',
    checkMailSent: 'Please check the email address you registered with us.'
  },
  common: {
    login: 'Login',
    valid: 'valid',
    emailAddress: 'Email address',
    password: 'Password',
    checkIn: 'Check-in',
    person: 'Person',
    adult: 'Adult',
    children: 'Children',
    coSleeping: 'Co-sleeping',
    bedSharing: 'Bed-sharing',
    settingPass: 'Reset Password',
    resetPassComplete: 'Password Reset Completed',
    pleaseEnterNewPass: 'Please Enter New Password',
    pleaseConfirmNewPass: 'Please Confirm New Password',
    passSettingComplete: 'Password setting is complete',
    forgotPassTitle: 'Forgot Password',
    weSendLink: 'We will send you a link to reset your password.',
    forgotPassFinish: 'Password forgotten email',
    registerDescription: 'Description about new members',
    remaingPoint: 'How many points you have left',
    searchCriteria: 'search criteria',
    searchResult: 'Search results',
    reordering: 'reordering',
    facility: 'Facility',
    noVacancy: 'no vacancy',
    freeParking: 'Free parking',
    noSmoking: 'No Smoking',
    smoking: 'smoking',
    belovedDog: 'beloved dog',
    noSmokingForDog: 'No Smoking for Dogs',
    smokingForPet: 'smoking a pet dog',
    aikenGardenSmall: 'Aiken Garden Small',
    myDogGardenLarge: 'My Dog Garden Large',
    nGauge: 'N gauge',
    vacant: 'vacant',
    fewRemaining: 'few remaining',
    noCheckin: 'No check-in',
    year: 'Year',
    month: 'Month',
    child: 'child',
    sleepTogether: 'Sleeping together available',
    noSleepTogether: 'No sleeping together',
    nRoom: 'Number of rooms',
    nGuest: 'Number of guests',
    total: 'total',
    room: 'room',
    night: 'night',
    firstName: 'First name',
    yen: 'yen',
    membersName: 'Member`s name',
    address: 'Address',
    profession: 'Profession',
    age: 'Age',
    sex: 'Sex',
    email: 'Email',
    passportNumber: 'Passport number',
    countryOfCitizenship: 'Country of Citizenship',
    loginEmailAddress: 'Login email address',
    sendingAddress: 'Sending address',
    loginPassword: 'Login password',
    dateOfBirth: 'Date of birth',
    phoneNumber: 'Phone number',
    phone: 'Phone',
    wellcome: 'Welcome',
    cardName: 'Card name',
    cardNumber: 'Card number',
    securityCode: 'Security code',
    ticketNumber: 'Ticket No.',
    authenticationNumber: 'Authentication number',
    faq: 'FAQ',
    contactUs: 'Contact us',
    expirationDate: 'Expiration date',
    dateAndTime: 'Date & time',
    contactAddress: 'Contact address',
    telephone: 'Telephone',
    creditCard: 'Credit card',
    introduction: 'Introduction',
    receipt: 'Receipt',
    registered: 'Registered',
    memberName: 'Member name',
    postalCode: 'Postal code',
    province: 'State/Province',
    district: 'City/District',
    street: 'Street',
    buildingName: 'Building/Apartment name, etc.',
    yourName: 'Your name',
    name: 'Name',
    surname: 'Surname',
    katakana: 'Katakana',
    hiragana: 'Hiragana',
    sei: 'Sei',
    mei: 'Mei',
    day: 'Day',
    relationship: 'Relationship',
    gender: 'Gender',
    male: 'Male',
    any: 'Any',
    female: 'Female',
    other: 'Other',
    sharingPoint: 'Sharing Point',
    flexPoint: 'Flex Point',
    remainingPoints: 'Remaining points',
    numberOfPointsRequired: 'Number of points required',
    availablePoints: 'Available points',
    beRedeemed: 'Be redeemed',
    currentPoints: 'Current points',
    addressee: 'Addressee',
    numberOfPointsBeforeExpiration: 'Number of points before expiration',
    endDate: 'End Date',
    nextDeadLine: 'Next Deadline',
    startDeadLine: 'Start Deadline',
    viewDetails: 'View Details',
    myWeek: 'My Week',
    contractYear: 'Contract Year',
    status: 'Status',
    rightsFacilities: 'Rights facilities',
    primeWeek: 'Prime Week',
    yearOfStartOfrightUse: 'Year of start of right use',
    weeklySpecialNumber: 'Weekly special number',
    roomType: 'Room Type',
    vacationMaster: 'Vacation Master',
    dateOfCommencementOfRightLodging: 'Date of commencement of right lodging',
    endDateOfRightLodging: 'End date of right lodging',
    contract: 'Contract',
    contractNumber: 'Contract Number',
    contractYears: 'Contract Years',
    contractExpirationDate: 'Contract Expiration Date',
    reservationId: 'Reservation ID',
    checkinDate: 'Check in Date',
    finalDate: 'Final Date',
    pleaseSelectTheDateYouWantToCancel: 'Please select the date you want to cancel',
    reservationTime: 'Reservation date and time',
    cancellationPolicy: 'Cancellation Policy',
    cancelPointName: 'Cancel <Point name>',
    totalCancellationPointName: 'Total cancellation <PointName>',
    restitutionPointName: 'Restitution <Point Name>',
    pleaseEnterYourLoginPassword: 'Please enter your login password',
    ownershipVacationMaster: 'Ownership Vacation Master',
    pastVacationMaster: 'Past Vacation Master',
    pointNameHistory: 'Point Name History',
    endOfTerm: 'End of term',
    remainingPointName: 'Remaining point name',
    expiryDate: 'Expiry Date',
    numberOfPointsRemainingForThisYear: 'Number of points remaining for this year',
    carryOverCharge: 'Carry-over charge Point unit price',
    numberOfAdditionalPoints: 'Number of additional points',
    current: 'current',
    endOfExpireDate: 'End of point expiration',
    additionalPointsPerUnit: 'Additional points per unit',
    additionalChargesWithTax: 'Additional charges (tax included)',
    schellingPoint: 'Schelling Point',
    do: 'do',
    fee: 'is fee',
    beCharged: 'You\'ll be charged',
    rightWeek: 'Right week',
    state: 'state',
    firstPart: 'First Part',
    secondPart: 'Second Part',
    deadlineForAcc: 'Deadline for acceptance',
    dateOfDivision: 'Date of division',
    numberOfNight: 'number of nights staying in accommodation',
    depositMWYear: 'Deposit MW year',
    formerFacilities: 'Former Facilities',
    formerRank: 'Former Rank',
    formerWeekNumber: 'former week number',
    scope: 'scope',
    selectDate: 'Select a date',
    SelectFac: 'Select a facility',
    searchByTag: 'Search by tag',
    numberPeople: 'Number of people utilized',
    petAllowed: 'Pet allowed',
    startDay: 'start day',
    missingPoint: 'Number of missing points',
    checkOut: 'Check out',
    capacity: 'capacity',
    totalPerMinute: 'Total points per minute',
    currentPoint: 'Points you currently have',
    afterBook: 'Points to consider after booking',
    dayBeforeEvent: 'days before the event',
    otherExpense: 'Other Expenses',
    allTaxInclude: 'All prices shown include tax',
    numberOfPointsUsed: 'Number of points used',
    cleaningFee: 'Cleaning fee',
    totalUsageFee: 'Total usage fee',
    rentalListingTotal: 'Rental listing total',
    totalCharge: 'Total harge',
    bookExchange: 'Book an exchange',
    requester: 'requester',
    afterReplacment: 'After replacement',
    fiscalYear: 'MW fiscal year',
    rank: 'Rank',
    exchangeFee: 'MW exchange fee',
    exchangedReservations: 'I exchanged reservations',
    memberRegistration: 'Vacation Style Member Registration',
    fullName: 'Full name',
    nameKana: 'Name (Kana)',
    cellPhoneNumber: 'cell phone number',
    corporationName: 'corporation name',
    corporateName: 'Corporate name',
    corporateNameNo: 'Corporate name number',
    nameOfPersonInCharge: 'Name of person in charge',
    nameDepartmentOrPosition: 'Name of the department or position of the person in charge',
    awardCode: 'Reward Code',
    loginID: 'Login ID',
    paymentMethod: 'Payment method',
    benefitsApplied: 'benefits applied',
    contractAutomaticRenewalSetting: 'Contract automatic renewal setting',
    contractDuration: 'Contract duration',
    contractStartDate: 'Contract start date'
  },
  statement: {
    companyAddress: 'Karuizawa',
    target: 'Target',
    totalUse: 'Total use',
    proviso: 'Proviso',
    companyNameText: 'Tokyu Vacations Karuizawa',
    addressText:
      '1016-652 Karuizawa, Karuizawa-cho, Kitasaku-gun, Nagano 389-0102 Japan',
    consumptionTax: 'Consumption tax',
    inlandRevenueTax: 'Inland revenue tax',
    accommodationTax: 'Accommodation tax',
    hotSpringTax: 'Hot spring tax',
    occupancyTax: 'Occupancy tax',
    mainPrice: 'Main price',
    roomCharge: 'Room charge',
    amountOfReceipt: 'Amount of receipt',
    noteReceipt: 'The above amount has been duly received'
  },
  vacancy: {
    vacancyNotificationList: 'Vacancy notification list',
    vehicleHeightLimit: 'Vehicle height limit',
    parkingLotRegistrationList: 'Parking lot registration list',
    bookWithMastersOption: 'Book with masters option',
    bookWithSharingPoint: 'Book with Sharing Point',
    bookWithFlexPoint: 'Book with Flex Point',
    dayPlanOrPreferentialRate: 'Day plan/preerential rate',
    kyoto28Weeks: 'Kyoto 28 weeks',
    karuizawa50Weeks: 'Karuizawa 50 weeks',
    bookAtMyWeek: 'Book at My Week',
    exchangeMyWeek: 'Exchange My Week',
    bookWith: 'booking',
    startDate: 'start date',
    endDate: 'end date'
  },
  buttons: {
    officialWebsite: 'Official website',
    cancel: 'Cancel',
    ok: 'OK',
    registerEmail: 'Register your email address',
    sending: 'Sending',
    register: 'Register',
    setUp: 'Set up',
    toLoginScreen: 'To login screen',
    showUserCertificate: 'Show user certificate',
    downloadDocuments: 'Download documents ',
    enterYourInfo: 'Enter your information',
    issueAReceipt: 'Issue a receipt',
    cancelNotification: 'Cancel notification',
    alreadyNotified: 'Notified',
    display: 'Display',
    change: 'Change',
    editDeputyMemberDetails: 'Edit deputy member details',
    editFamilyMemberDetails: 'Edit family member details',
    addFamilyMembers: 'Add family members',
    save: 'Save',
    findAnAddress: 'Find an address',
    changeToFamilyMemberAndSave: 'Change to Family member and save',
    deleteThisMember: 'Delete this member',
    selectATicket: 'Select a ticket',
    return: 'Return',
    enterNumberOfTickets: 'Enter number of tickets',
    order: 'Order',
    confirmNumberOfTickets: 'Confirm number of tickets',
    partialCancellation: 'Partial cancellation',
    reservationDetails: 'Reservation Details',
    addPointName: 'Add point name',
    carryOverBtn: 'point name carry over',
    addBtn: 'Add <point name>',
    xxThePointName: 'XX the pointname',
    backToTop: 'Back to top',
    showReceipts: 'Show receipts',
    reserveDiv: 'Reservation division',
    deposit: 'deposit',
    divideWholeReservation: 'Divide the whole',
    divideFirstHalfReservation: 'Split the first half',
    divideSecondHalfReservation: 'Book the second half',
    split: 'split',
    returnToTop: 'Return to TOP page',
    totalDeposit: 'total deposit',
    firstDeposit: 'Deposit the first half',
    secondDeposit: 'Deposit the second half',
    makeDeposit: 'make deposit',
    completeSelection: 'Complete your selection',
    decision: 'decision',
    front: '<point name>Front fall',
    whole: 'Whole Exchange Vacancy Search',
    firstPart: 'Search for vacant rooms in the first half exchange',
    latePart: 'Search for vacancies in the late exchange',
    reserveAndExchange: 'Reserve and exchange',
    viewTheListOfReceipts: 'View the list of receipts',
    clickHereForDetails: 'Click here for details',
    viewMore: 'View more',
    toTokyuWebsite: 'To Tokyu Vacations official website',
    viewUsageGuide: 'View usage guide',
    vacationLifeBackNumber: 'Vacation life back number',
    viewQRUsage: 'View QR / usage certificate',
    search: 'Search',
    selectDate: 'Select date',
    selectFacility: 'Select facility',
    selectRoomType: 'Select room type',
    selectTheRoomTypeAbove: 'Select the room type above',
    numberOfPersons: 'Number of persons',
    confirm: 'Confirm',
    ticketUserPage: 'Ticket user page',
    plusPoints: 'Point addition / forwarding application',
    usePoint: 'Applications that use previous points',
    next: 'Next',
    loginSecond: 'Login',
    appAddPoint: 'Application to add points',
    pointForApp: 'Point forwarding application',
    checkUsage: 'Please check your usage',
    send: 'Send',
    changeTheCondition: 'Change the conditions',
    inOrderOfLeast: 'In order of least points',
    openFacilityInformation: 'Open Facility Information',
    goToReservation: 'Go to reservation',
    addWaitList: '空室通知へ進む',
    notify: 'I want to be notified',
    stop: 'stop',
    checkIn: 'Check-in',
    home: 'home',
    registerComplete: 'Registration of membership information has been completed.',
    completePleaseWait1: '登録頂いた内容の確認には数日お時間をいただきますので、ご利用開始まで暫くお待ち下さい。',
    completePleaseWait2: '確認完了後、ご登録頂いたメールアドレスに通知をお送り致しますので、メールに記載されたマイページよりログインをお願い致します。'
  },
  daysOfWeek: {
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun'
  },
  unit: {
    point: 'Point',
    points: 'Points',
    ticket: 'Ticket',
    tickets: 'Tickets'
  },
  text: {
    roomType: {
      select: 'Select a room type',
      pleaseSelect: 'Please select your room type and preferred date'
    },
    reservationCalendar: {
      select: 'Select your desired date'
    },
    facility: {
      select: 'Select facilities',
      pleaseSelect: 'Please select the facilities you would like to stay'
    },
    vacancyUn: {
      full: 'The room is full for the period you requested. Would you like to be notified when a room becomes available?',
      desc: 'You can check and change your notification settings at any time from the "Waiting List" menu'
    },
    selectPlan: {
      pleaseSelect: 'Please select a plan'
    },
    stage: {
      planSelection: 'Plan selection',
      reservationConfirmation: 'Reservation Confirmation',
      dataEntry: 'data entry',
      decision: 'decision',
      memberInformation: 'member information',
      paymentMethod: 'payment method',
      confirmation: 'confirmation',
      complete: 'complete'
    },
    reservationConfirm: {
      please: 'Please confirm your reservation details',
      cancellationPolicyDesc: 'Cancellation up to 8 days in advance is free of charge, after that, 7 days in advance 20%, 2 days in advance 50%, on the day of cancellation 80%, no contact 100% of the points will be charged as cancellation fee.',
      cancellationPolicyWarm: 'If you cancel your reservation after it has been made, you will be charged a cancellation fee.',
      otherExpense: 'A cleaning fee of 4000 yen is required for this facility. In addition, there is a usage fee of 1500x3 nights x number of people.'
    },
    register: {
      homeAddress: 'Home Address',
      homeContact: 'Home contact',
      companyContact: 'Company contact',
      title: 'Register membership information',
      fill: 'Your email address has been successfully verified. Please register the following information.',
      changeEmail: 'You can change your e-mail address on My Page.',
      passwordHint: '*Please enter at least 8 characters including English numerals.',
      passwordCon: 'Password (for confirmation)',
      required: 'Your email address and password are required to login to My Page.',
      nameNotChange: '*You cannot change your name once you have registered.',
      birthNotChange: '*You cannot change your birth date once you have registered it.',
      yourAddress: 'Your address',
      companyAddress: ' Company address',
      postalCodeExample: 'Example: 150-0002 (half-angle)',
      addressSearch: 'Address Search',
      provinceExample: 'Example: Tokyo Metropolitan Government',
      districtExample: 'Example: Shibuya Ward',
      buildingNameExample: 'Example: Shibuya Subway Building 3F',
      streetExample: 'Example: Shibuya 1-16-14',
      optionalFill: 'Please enter one of these',
      phoneNumberExample: 'Example: 03-3406-0109 (half width)',
      cellPhoneNumberExample: 'Example: 090-1234-5678 (half width)',
      descPrevent: 'To prevent duplicate registration of customer information, please enter your Tokyu Vacations membership number if you have one. If you are registering for the first time, you do not need to enter this information.',
      corporationNameExample: 'Example: Tokyu Sharing Corporation',
      corporateNameExample: 'e.g., kabushiki kaisha tokyu sharing',
      corporateNameNoExample: 'Example: 1234123412345',
      nameOfPersonInChargeExample: 'Example: Taro Tokyu (full-width)',
      katakanaExample: 'Example: Tohkyuu Tarou (double-byte)',
      departmentExample: 'Example: Sales Department',
      positionExample: 'Example: department head',
      exitDesc: '*If you are an existing member, please enter. Phone number',
      registerPayment: 'Please register how you would like to pay your Vacation Style membership fee.',
      whatIsSecCode: 'What is a security code?',
      ifAwardCode: 'If you have an award code, please enter it.',
      pleaseConfirm: 'Please confirm the membership information you have entered.',
      pleaseAnswer: 'Please answer the following questionnaire.',
      howDidHear: 'How did you hear about Tokyu Vacations?',
      webSearch: 'Web search',
      officialSNS: 'official SNS',
      accommodationBooking: 'accommodation booking site',
      tv: 'television',
      magazine: 'magazine',
      introduction: 'Introduction',
      JALVacations: 'JAL Vacations',
      answeredOther: 'If you answered "Other," please enter the following',
      answeredOtherOptional: 'If you answered "other," please provide specific details. (Optional)',
      getStartBill: 'About your start and billing',
      confirmDesc: 'After completing the registration, you can start using the room. The membership fee will be charged in the following month. The contract will be automatically renewed.',
      membershipIncluded: 'Membership agreement will be included',
      notationBased: 'Notation based on specific commercial transactions posted',
      agree: 'I agree to the above terms.',
      pleaseSure: '*Please make sure you have read and fully understood the terms and conditions before registering.'
    },
    notRegistered: {
      title: 'About the vacancy notice register list',
      text: 'Here you can see the vacancy notices you have registered in the past. If you would like to register a new vacancy notice, please use the vacancy search.'
    },
    pleaseEnterPetInfo: 'Please enter the required information to proceed'
  },

  // ================================ Pages ================================
  loginPage: {
    saveYourLoginID: 'Save your login ID',
    forgotYourPassword: 'Forgot your password',
    forgotYourId: 'Please add this text If you forgot your ID, please contact our call center (0120-618-109)',
    membershipAgree: 'Membership Agreement'
  },
  homePage: {
    pleaseBeSureToReadIt: 'Please be sure to read it',
    latestReservation: 'Latest reservation',
    downloadDocuments: 'Download documents',
    FAQ: 'FAQ',
    membersOnlyDial: 'Members only dial',
    searchConditions: 'Search conditions',
    freeParking: 'Free parking',
    noSmoking: 'No smoking',
    smoking: 'Smoking',
    urgentInformation: 'Urgent information',
    viewUsageCertificate: 'Show the usage certificate',
    vacationLifeBackNumber: 'Vacation life back number',
    callCenterDial: 'Call center dial',
    availableServiceNotice: {
      line1: 'The service will be available soon!',
      line2: 'Please enter your information before you start using the service.'
    },
    notification: {
      expirationDateSharingPoints:
        'The expiration date for the sharing points in your contract is approaching.',
      exchangingMyWeekDeadline:
        'The deadline for exchanging the deposited My Week is approaching.',
      depositMyWeekDeadline: 'The deposit deadline for My Week is approaching.'
    }
  },
  newsPage: {
    memberEventInfo: 'Member event infomation',
    urgentInformation: 'Urgent information',
    contractList: 'Contract list'
  },
  contract: {
    title: {
      title1: 'Official week',
      title2: 'Inflection point',
      title3: 'New fixed goods',
      title4: 'My week'
    },
    contractYear: {
      contractYear1: 'Contract year',
      contractYear2: 'Score within the time limit',
      week: 'Week'
    },
    year: {
      year1: 'Year',
      point: 'Point'
    },
    rightBasis: {
      rightBasis1: 'Rights facility',
      rightBasis2: 'Expiration time'
    },
    btn: 'See details',
    to: 'To',
    deadline: 'Next deadline',
    deadlineStart: 'The period begins',
    day: 'Accommodation date',
    form: 'Start',
    end: 'End'
  },
  contractDetail: {
    membershipNumber: 'Membership number',
    contract: 'Contract',
    pointsRemaining: 'Points left',
    expirationDatePoint: 'Expiration date of points',
    pointsLeft: 'Points remaining after the next financial year'
  },
  contractReq: {
    historyStatus: 'Application history status',
    forwarding: 'Point carry forward',
    addPoint: 'Add point'
  },
  typography: {
    weSendLinkForgot: 'We have sent a link to reset your password to your email address, so please proceed from there.',
    registerSubTitle: 'テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト'

  },
  registerPage: {
    note1: 'Notation based on the Specified Commercial Transactions Law',
    note2: 'Specified business will be posted Please specify the content to be posted.',
    confirmed: 'Confirmed',
    registerWithEmail: 'Register with email address',
    registerDesContent: 'The email address you enter will be your first "login ID". You can change your login ID on the information registration screen after email authentication or by editing the registration information on My Page.',
    memberType: 'Member registration',
    individual: 'Individual',
    corporation: 'Corporation',
    email: 'メールアドレス',
    emailConfirmed: 'メールアドレス（確認用）',
    required: 'required',
    policy: 'privacy policy',
    agreePolicy: 'I agree to the above terms',
    submit: 'Register',
    submitDescription: '* If you do not receive the membership registration email, please change the settings so that you can receive "@tokyu-sharing.co.jp". * Click here if you do not receive the email'
  },
  reservationAndUsageHistory: {
    reservationAndUsageHistory: 'Reservation & Usage history',
    reservationDetails: 'Reservation details',
    reservationChange: 'Reservation change',
    confirmation: 'Confirmation',
    theLatest: 'The latest',
    enterInformationAndConfirmUsage: 'Enter information and confirm usage',
    currentRoomReservation: 'Current room reservation',
    pastAccommodationReservations: 'Past accommodation reservations',
    changeReservationDetails: 'Change reservation details',
    pleaseSelectTheContentToBeChangedFromTheFollowing: 'Please select the content to be changed from the following',
    extensionOfAccommodationDate: 'Extension of accommodation date',
    changeGuestInformation: 'Change guest information',
    changeRentalListing: 'Change rental listing',
    changeParkingLot: 'Change parking lot',
    return: 'return',
    accommodationDateExtension: 'Accommodation Date Extension',
    accommodationDateExtensionTitle: 'If you want to add more days before the first day of your stay, please make a new reservation. If you want to reduce the accommodation schedule, please use the reservation cancellation menu.',
    extendTheDay: 'Extend the day',
    save: 'Save',
    byAddingDays: 'By adding days',
    thereIsAnAdditionalChargePoints: 'There is an additional charge / points',
    additionalChargesAdditionalPointsDueToAdditionalDays: 'Additional charges / additional points due to additional days',
    allListedPricesIncludeTax: 'All listed prices include tax',
    pleaseEnter: 'Please enter',
    yen: 'yen',
    loginPasswordForConfirmation: 'Login password for confirmation',
    guestInformation: 'Guest Information',
    pleaseEnterTheChangeInTheDesiredItem: 'Please enter the change in the desired item',
    adult: 'Adult',
    name: 'Name',
    child: 'Child',
    thereIsCoSleeping: 'There is co-sleeping',
    noBedSharing: 'No bed-sharing',
    aboutCheckIn: 'About check-in',
    pleaseCheckInFrom0To0: 'Please check in from 0:00 to 0:00',
    aboutCheckInText: 'If you are outside the above hours, please contact the facility.',
    accommodationRepresentative: 'Accommodation representative',
    guestName: 'Guest name',
    mandatory: 'Mandatory',
    changeToTheAboveContent: 'Change to the above content',
    rentalListingChange: 'Rental Listing Change',
    noRentalListingRequired: 'No rental listing required',
    borrowRentalListing: 'Borrow a rental listing',
    lendingPeriod: 'Lending period',
    numberOfRentalListingGroups: 'Number of rental listing groups',
    rentalExhibitionGroup: 'Rental exhibition group',
    usedForAllDates: 'Used for all dates',
    useOnlyForSpecificDates: 'Use only for specific dates',
    rentalListing: 'Rental listing',
    totalRentalAmount: 'Total rental amount',
    totalFee: 'Total fee',
    numberOfCars: 'Number of cars',
    car: 'Car',
    number: 'Number',
    colour: 'Colour',
    startDateOfUse: 'Start date of use',
    endOfUseDate: 'End of use date',
    from: 'From',
    parkingLotFormat: 'Parking lot format',
    mechanical: 'Mechanical',
    flatPlacement: 'Flat placement',
    vehicleHeightLimit: 'vehicle height limit cm',
    noVehicleHeightLimit: 'no vehicle height limit',
    full: 'Full',
    registerWaitingForCancellation: 'Register waiting for cancellation',
    parkingLot: 'Parking lot',
    use: 'Use',
    doNotUse: 'Do not use',
    flat: 'Flat',
    seeMore: 'See more',
    usageHistory: 'Usage history',
    dateAndTime: 'Date and time',
    username: 'Username',
    operation: 'Operation',
    guest: 'Guest',
    accommodationSchedule: 'Accommodation schedule',
    lendingSchedule: 'Lending schedule',
    noParkingRequired: 'Parking',
    parking: 'Parking',
    noFreeRoomsInRoomType: 'There is no free room in this room type',
    overMaxOccupancy: 'The selected number of guests cannot exceed the room capacity',
    izuImaihamaGuestNotes: '※定員5名\nただし、未就学児１名のみ「添い寝あり」で追加（合計６名）選択が可能です。',
    enterAccommodationDetails: 'There is no free room in this room type'
  },

  memberInfo: {
    publish: 'Publish',
    pointName: 'Point name',
    description: 'If you change your email address, your login email address (login ID) will not be changed. If you wish to change your login ID as well, please contact our call center.',
    processing: 'Processing',
    changeCreditCardInfo: 'Change credit card information',
    haveChangedCreditCardInfo: 'I have changed my credit card information',
    registerCreditCardInfo: 'Register credit card information',
    vacationsPoints: 'Vacations points',
    vacationsPointsHistory: 'Vacations points history',
    reservationUse: 'Reservation use',
    changeContact: 'Change contact information',
    changeMFAPolicy: 'ログイン時認証レベル変更',
    membershipType: 'Membership type',
    changeMembersInfo: 'Change deputy/family member information',
    aboutPayment: 'About payment',
    confirmOrChangePaymentMethod: 'Confirmation / change of payment method',
    changeRegisteredCreditCard: 'Change your registered credit card',
    introduction:
      'If you introduce a friend or acquaintance and make a contract, we will give you a privilege.',
    enterInfoFriendsOrCcquaintances:
      'Enter information about friends / acquaintances',
    currentPaymentMethod: 'Current payment method',
    payAtTheFacility: 'Pay at the facility',
    facilityFee: 'Facility fee',
    setUpFee: 'Set up fee',
    optionalCleaningFee: 'Optional cleaning fee',
    inlandRevenueAndAccommodationTax: 'Inland revenue tax , accommodation tax',
    paidService: 'Paid service',
    deputyMember: 'Deputy member',
    familyMember: 'Family members',
    useSameAddressAsMember: 'Use the same address as a regular member',
    introductionTextList: {
      line1: 'If you have any friends or acquaintances who are interested in Tokyu Vacations, please introduce them to us.',
      line2: 'If the person who introduced you to us signs a contract, we will reward both you and the person who introduced you to us with a special offer.',
      line3: 'Our sales representative will contact you at the phone number of the customer introduced below, so please let us know in advance.'
    },
    enterFriendInfo: 'Please enter the information of your friend/acquaintance',
    thankYouForIntro: 'Thank you very much for the introduction.',
    thanhYouForIntroDescription: 'If the introduced customer makes a contract, the sales staff will contact you with thanks and information on the benefits, so we appreciate your continued patronage.'
  },
  giftTicket: {
    alreadyApplied: 'Already applied',
    ticketIssuerConfirmed: 'Ticket issuer confirmed',
    selectThePointsToUse: 'Select the points you want to use',
    enterNumOfTicket: {
      line1: 'Enter',
      line2: 'Number of tickets'
    },
    numberOfPointsAfterExchange: 'Number of points after exchange',
    orderNoticeSending: 'It will be sent to the address registered as a member. If you want to change it, please change the following.',
    orderCompleteTitle: 'Order completed',
    orderCompleteDetail: 'Your order has been processed, please wait for the items to be shipped.'
  },
  rules: {
    emailIsRequired: 'E-mail is required',
    emailMustBeValid: 'E-mail must be valid',
    emailNotMatch: 'Email does not match',
    passwordIsRequired: 'Password is required',
    passwordRules:
      'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
    passwordNotMatch: 'Password does not match',
    invalid: 'Invalid',
    isRequired: 'Is required',
    required: 'Required',
    childrenAge: 'Children under the age of 18 cannot register',
    hyphenInHalfWidthNumbersNotice:
      'Please enter a hyphen in half-width numbers',
    insufficientPoints: 'Insufficient points',
    pointsNeededToRedeem: 'Redemption of points starts from {points} points'
  },
  downloadDocuments: {
    downloadDocuments: 'Download document',
    download: 'Download',
    clickHereForLastYear: 'Click here for last year s usage guide',
    vacationLifeBackNumber: 'Vacation life back number'

  },
  // ------------------------------- :)) -------------------------------
  toast: {
    checkEmail: 'Please check the URL for login sent to you by e-mail',
    welcome: 'Welcome'
  }
}
